import React from "react";
import apporach_dots from "../assets/img/svg/apporach_dots.svg";
import Lottie from "react-lottie";
import animationData from "../assets/gif/Ibeify_Our Approach.json";

const OurApproach = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <div className="container px_75 overflow-hidden"
        id="ourapproach"
      >

        <h2
          className="ff_Gilory font-bold text-[35px] md:text-lg text-center text-lightblue lg:mb-16 pt-[60px] xl:pt-[90px]"
          data-aos="fade-right"
          data-aos-delay="100"
        >
          Our <span className="text-lightgreen">Approach</span>
        </h2>
        <div className=" flex-col-reverse flex lg:flex-row justify-center items-center lg:pb-[46px] md:mt-0">
          <div className="w-auto">
            <div
              className="flex items-start"
              data-aos="fade-right"
              data-aos-delay="200"
            >
              <img className="w-[34px]" src={apporach_dots} alt="dots" />
              <div className="ms-[15px]">
                <p className="font-bold text-[18px] md:text-md ff_montserrat">
                  We can do it for you
                </p>
                <p className="text-xs xl:text-sm font-normal ff_montserrat opacity-70 max-w-[398px]">
                  We will embed on your team and work with you directly with
                  you.
                </p>
              </div>
            </div>
            <div
              className="flex items-start my-9 lg:my-12 xl:my-[99px]"
              data-aos="fade-right"
              data-aos-delay="300"
            >
              <img className="w-[34px]" src={apporach_dots} alt="dots" />
              <div className="ms-[15px]">
                <p className="font-bold text-[18px] md:text-md ff_montserrat">
                  We can support you
                </p>
                <p className="text-xs xl:text-sm font-normal ff_montserrat opacity-70 max-w-[453px]">
                  We will support your current efforts and technology
                  initiatives through training and R&D
                </p>
              </div>
            </div>
            <div
              className="flex items-start"
              data-aos="fade-right"
              data-aos-delay="400"
            >
              <img className="w-[34px]" src={apporach_dots} alt="dots" />
              <div className="ms-[15px]">
                <p className="font-bold text-[18px] md:text-md ff_montserrat">
                  We can do it with you
                </p>
                <p className="text-xs xl:text-sm font-normal ff_montserrat opacity-70 max-w-[398px]">
                  We take your high-level requirements within an agreed upon
                  time frame
                </p>
              </div>
            </div>
          </div>
          <div
            className="mb-5 lg:mb-0 w-auto approach_lottie"
            data-aos="fade-left"
            data-aos-delay="500"
          >
            <Lottie options={defaultOptions} height={400} width={900} />
          </div>
        </div>
      </div>
    </>
  );
};

export default OurApproach;
