import React from "react";
import consulting_supproting from "../assets/img/svg/consulting_supproting.svg";
import observability from "../assets/img/svg/observability.svg";
import ApplicationDelivery from "../assets/img/svg/ApplicationDelivery.svg";
import ContainerizedPlatformManagement from "../assets/img/svg/ContainerizedPlatformManagement.svg";

const OurServices = () => {
  return (
    <>
      <div
        className="container px_75 lg:pb-[74px] overflow-hidden"
        id="service"
      >
        <h2
          className="text-center ff_montserrat font-semibold   text-[35px] md:text-lg text-lightgreen mb-[30px] lg:mb-[78px] mt-[61px]"
          data-aos="fade-top"
          data-aos-delay="50"
        >
          Our <span className="text-lightblue">Services</span>
        </h2>
        <div className="max-w-[1234px] mx-auto">
          <div
            className="translate_box flex-col md:flex-row flex max-w-[1160px] md:-translate-x-[5%] xl:translate-x-[0%] mr-auto 2xl:mx-auto 2xl:-translate-x-[5%] items-center "
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <div className="bg-lightblue rounded-[4px] services_box duration-300 relative z-10 py-[10px] px-[80px] md:px-[45px] md:py-[65px] shadow-4xl">
              <div className="flex md:flex-col items-center flex-row justify-center">
                <p className="text-white font-semibold text-basemd pe-4 md:pe-0 md:mb-2">
                  01
                </p>
                <img
                  className="w-[35%] md:w-full"
                  src={consulting_supproting}
                  alt="consulting Supproting"
                />
              </div>
            </div>
            <div className="bg-white px-[18px] sm:px-[30px] -mt-[20px] md:mt-0 rounded-lg shadow-[9px_0px_34px_rgba(0, 0, 0, 0.05)] relative py-[41px] md:py-[20px] lg:py-[35px] xl:py-[51px] ml-0 xl:-ml-2 shadow-3xl">
              <p className="ff_montserrat text-[22px] font-semibold sm:text-md mb-2 text-center md:text-start">
                Consulting & Supporting
              </p>
              <p className="ff_montserrat font-normal text-xs xl:text-sm opacity-70 text-center md:text-start xl:max-w-[950px]">
                Our process evaluation provide clients with a comprehensive
                assessment of their current processes and technologies. We offer
                actionable recommendations to improve efficiencies and align
                with future technical needs, including process re-architecture,
                technology upgrades, or strategic initiatives. We provide
                implementation support, including training and project
                management, to ensure successful implementation of our
                recommendations
              </p>
            </div>
          </div>
          <div
            className="translate_box2 flex flex-col-reverse md:flex-row max-w-[1160px] ml-auto items-center mt-[30px]"
            data-aos="fade-left"
            data-aos-delay="200"
          >
            <div className="bg-white px-[18px] sm:px-[30px] rounded-lg shadow-[9px_0px_34px_rgba(0, 0, 0, 0.05)] relative py-[41px] md:py-[20px] lg:py-[18px] xl:py-[25px] ml-0 xl:-ml-2 -mt-[20px] md:mt-0 shadow-3xl">
              <p className="ff_montserrat font-semibold text-md mb-2 text-center md:text-start">
                Observability
              </p>
              <p className="ff_montserrat font-normal text-xs xl:text-sm opacity-70 text-center md:text-start xl:max-w-[982px]">
                Our comprehensive monitoring services offer full support for the
                setup, maintenance, and ongoing management of monitoring
                environments. We work with our clients to select and set up an
                observability platforms, configuring tooling, setting up
                alerting systems, and integrating with third-party services. If
                the customer does not have a preferred platform, we can help
                them select the most appropriate platform for their needs,
                considering factors such as scalability, cost, and integration
                with other tools and systems. We provide ongoing support,
                including troubleshooting issues, applying updates and patches,
                and optimizing performance
              </p>
            </div>
            <div className="bg-[#0AC5A8] rounded-[4px] services_box2 duration-300 relative z-10 py-[10px] px-[80px] md:px-[45px] md:py-[65px] shadow-4xl xl:-translate-x-2">
              <div className="flex md:flex-col items-center flex-row justify-center">
                <p className="text-white font-semibold text-basemd pe-4 md:pe-0 md:mb-2">
                  02
                </p>
                <img
                  className="w-[35%] md:w-full"
                  src={observability}
                  alt="observability"
                />
              </div>
            </div>
          </div>
          <div
            className="translate_box flex-col md:flex-row flex max-w-[1160px] md:-translate-x-[5%] xl:translate-x-[0%] mr-auto 2xl:mx-auto 2xl:-translate-x-[5%] items-center mt-[30px]"
            data-aos="fade-right"
            data-aos-delay="300"
          >
            <div className="bg-[#869CB8] rounded-[4px] services_box duration-300 relative z-10 py-[10px] px-[80px] md:px-[45px] md:py-[65px] shadow-4xl">
              <div className="flex md:flex-col items-center flex-row justify-center">
                <p className="text-white font-semibold text-basemd pe-4 md:pe-0 md:mb-2">
                  03
                </p>
                <img
                  className="w-[35%] md:w-full"
                  src={ContainerizedPlatformManagement}
                  alt="Containerized Platform Management"
                />
              </div>
            </div>
            <div className="bg-white px-[18px] sm:px-[30px] -mt-[20px] md:mt-0 rounded-lg shadow-[9px_0px_34px_rgba(0, 0, 0, 0.05)] relative py-[41px] md:py-[20px] lg:py-[38px] ml-0 xl:-ml-2 shadow-3xl">
              <p className="ff_montserrat font-semibold text-md mb-2 text-center md:text-start">
                Containerized Platform Management
              </p>
              <p className="ff_montserrat font-normal text-xs xl:text-sm opacity-70 text-center md:text-start xl:max-w-[954px]">
                Our Contaniner Orchestration services provide end-to-end
                management of Kubernetes clusters, including creation,
                maintenance, and resource management. We work with our clients
                to design resource management strategies that optimize
                utilization and reduce costs.Our implementation and management
                services simplify the process of managing applications and
                infrastructure in Kubernetes, and can help our clients increase
                the speed and frequency of deployments
              </p>
            </div>
          </div>
          <div
            className="translate_box2 flex flex-col-reverse md:flex-row max-w-[1160px] ml-auto items-center mt-[30px]"
            data-aos="fade-left"
            data-aos-delay="400"
          >
            <div className="bg-white px-[18px] sm:px-[30px] rounded-lg shadow-[9px_0px_34px_rgba(0, 0, 0, 0.05)] relative py-[41px] md:py-[20px] lg:py-[38px] ml-0 xl:-ml-2 -mt-[20px] md:mt-0 shadow-3xl">
              <p className="ff_montserrat font-semibold text-md mb-2 text-center md:text-start">
                Application Delivery
              </p>
              <p className="ff_montserrat font-normal text-xs xl:text-sm opacity-70 text-center md:text-start xl:max-w-[950px]">
                We provide custom CI/CD pipeline services, working with clients
                to design and implement pipelines that meet their specific
                needs. Our services include configuring build and deployment
                processes, setting up testing and quality assurance (QA)
                frameworks, and integrating with third-party tools and
                services.We offer ongoing maintenance and support for CI/CD
                pipelines, including troubleshooting issues, applying updates
                and patches, and optimizing performance.
              </p>
            </div>
            <div className="bg-[#51F3DA] rounded-[4px] services_box2 duration-300 relative z-10 py-[10px] px-[80px] md:px-[45px] md:py-[65px] shadow-4xl xl:-translate-x-2">
              <div className="flex md:flex-col items-center flex-row justify-center">
                <p className="text-white font-semibold text-basemd pe-4 md:pe-0 md:mb-2">
                  04
                </p>
                <img
                  className="w-[35%] md:w-full"
                  src={ApplicationDelivery}
                  alt="ApplicationDelivery"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurServices;
