import React, { useState } from "react";
import animationData from "../assets/gif/iBeify_About.json";
import Lottie from "react-lottie";
import VisibilitySensor from "react-visibility-sensor";
import { IbeifyLogo } from "./common/Icons";

const Transformation = () => {
  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <div
        className="container px_75 lg:pb-[61px] pt-[59px] xl:pt-[118px] overflow-hidden"
        id="about-us"
      >
        <div className="flex justify-between items-center flex-col lg:flex-row">
          <div
            className="sm:w-[80%] md:w-[70%] lg:w-[60%] xl:w-2/4 lg:px-3 about_lootie"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <VisibilitySensor>
              {({ isVisible }) => {
                return (
                  <Lottie
                    isPaused={isVisible ? false : true}
                    options={defaultOptions}
                    height={400}
                    width={900}
                  />
                );
              }}
            </VisibilitySensor>
          </div>
          <div
            className="lg:w-[70%] xl:w-[46%] lg:px-3 mt-8 xl:mt-0"
            data-aos="fade-left"
            data-aos-delay="200"
          >
            <p className="ff_montserrat text-base font-normal text-center sm:text-start">
              About Us
            </p>
            <h2 className="ff_montserrat font-semibold text-[28px] sm:text-lg text-lightgreen sm:max-w-[554px] md:leading-[57.6px] mb-[15px] text-center sm:text-start">
              Transformation{" "}
              <span className="ff_Gilory font-bold text-lightblue">
                Through Partnership
              </span>
            </h2>
            <p className="ff_montserrat font-normal text-xs xl:text-sm opacity-70 mb-[9px] leading-[25.6px] text-center sm:text-start">
              Ibeify is a full-service technical consulting firm that
              specializes in utilizing cloud technologies, infrastructure
              monitoring, and container orchestration to design and implement
              scalable, resilient, and dynamic cloud-native systems for our
              clients.
            </p>
            <p className="ff_montserrat font-normal text-xs xl:text-sm opacity-70 mb-[9px] leading-[25.6px] text-center sm:text-start">
              Our team utilizes best practices and industry-accredited tooling
              to optimize the management of resources and reduce the overhead
              and complexity of running applications and their underlying
              infrastructure in the cloud. We leverage automated resource
              management techniques to further streamline the process and
              enhance the reliability and performance of our clients' systems.
            </p>
            <p className="ff_montserrat font-normal text-xs xl:text-sm opacity-70 mb-[9px] leading-[25.6px] text-center sm:text-start">
              At Ibeify our vision is to truly empower companies to embrace
              innovation by providing our customers with unique solutions to
              addressing their technical challenges.
            </p>
            <p className="ff_montserrat font-normal text-xs xl:text-sm opacity-70 mb-[9px] leading-[25.6px] text-center sm:text-start">
              We look forward to partnering with you and helping your projects
              reach their full potential through our comprehensive range of
              services.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Transformation;
