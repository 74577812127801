import React from "react";
import logo from "../assets/img/svg/nav_logo.svg";

const Loading = () => {
  return (
    <>
      <div className="min-h-screen bg-[url('./assets/img/webp/header_bg.webp')] bg-no-repeat bg-cover fixed top-0 w-full z-50 flex justify-center items-center">
        <img className="animate_loading" src={logo} alt="logo" />
      </div>
    </>
  );
};

export default Loading;
