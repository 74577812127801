import React, { useEffect, useState } from "react";
import OurApproach from "../components/OurApproach";
import OrganizationAdmire from "../components/OrganizationAdmire";
import OurServices from "../components/OurServices";
import WeUse from "../components/WeUse";
import Form from "../components/Form";
import Footer from "../components/Footer";
import Transformation from "../components/Transformation";
import Nav from "../components/Nav";
import Hero from "../components/Hero";
import Loading from "../components/Loading";
import AOS from "aos";
import "aos/dist/aos.css";


const Home = () => {
  const [preload, setpreload] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setpreload(false);
      document.body.classList.remove("overflow_hidden_preloder");
    }, 2800);
  });
  useEffect(() => {
    AOS.init({
      once: true,
    });
  });
  return (
    <>
      <div className="bg-[#F5F5F5]">
        {preload && <Loading />}
        <div className="bg-[url('./assets/img/webp/header_bg.webp')] bg-no-repeat bg-cover xl:min-h-screen flex flex-col">
          <Nav />
          <div className="flex flex-grow items-center justify-center overflow-hidden">
            <Hero />
          </div>
        </div>
        <Transformation />
        {/* <WeUse /> */}
        <OurServices />
        {/* <OrganizationAdmire /> */}
        <OurApproach />
        {/* <Form /> */}
        <Footer />
      </div>
    </>
  );
};

export default Home;
