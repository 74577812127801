import React, { useState } from "react";
import logo from "../assets/img/svg/nav_logo.svg";
import nav_close from "../assets/img/svg/charm_cross.svg";

const Nav = () => {
  const [open, setclose] = useState(false);
  if (open) {
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
  }
  return (
    <>
      <div className="container px_75">
        <div className="flex items-center pt-6 justify-between">
          <a href="/">
            <img className="w-[150px] lg:w-[200px]" src={logo} alt="logo" />
          </a>
          <button
            onClick={() => setclose(!open)}
            className="flex flex-col md:hidden bg-transparent border-0 relative"
          >
            <span className="h-[3px] w-[35px] bg-white inline-block"></span>
            <span className="my-2 h-[3px] w-[35px] bg-white inline-block"></span>
            <span className="h-[3px] w-[35px] bg-white inline-block"></span>
          </button>
          <div
            className={
              open
                ? "ms-0 h-full w-full absolute top-0 left-0 duration-300 nav_open"
                : "h-full w-full absolute top-0 left-0 duration-300 -ml-[100%] nav_open"
            }
          >
            <ul className="flex items-center gap-[27px] h-full md:justify-between flex-col md:flex-row justify-center relative">
              <span
                className="absolute top-[25px] right-[25px] sm:right-[55px] md:hidden w-[30px]"
                onClick={() => setclose(false)}
              >
                <a href="#">
                  <img src={nav_close} alt="nav_close" />
                </a>
              </span>
              <li>
                <a
                  href="/"
                  className="text-white text-[22px] md:text-xs xl:text-sm font-normal ff_montserrat relative nav_links text_stroke"
                  onClick={() => setclose(false)}
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="#about-us"
                  className="text-white text-[22px] md:text-xs xl:text-sm font-normal ff_montserrat relative nav_links nav_btn_stroke"
                  onClick={() => setclose(false)}
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="#service"
                  className="text-white text-[22px] md:text-xs xl:text-sm font-normal ff_montserrat relative nav_links nav_btn_stroke"
                  onClick={() => setclose(false)}
                >
                  Service
                </a>
              </li>
              <li>
                <a
                  href="#ourapproach"
                  className="text-white text-[22px] md:text-xs xl:text-sm font-normal ff_montserrat relative nav_links nav_btn_stroke"
                  onClick={() => setclose(false)}
                >
                  Our Approach
                </a>
              </li>
              {/* <li>
                <a
                  href="#contact-us"
                  className="text-white text-[22px] md:text-xs xl:text-sm font-semibold ff_montserrat py-[14px] px-6 border-white border-[1.5px] rounded-[100px] hover:text-lightgreen hover:bg-white duration-300 inline-block"
                  onClick={() => setclose(false)}
                >
                  Contact Us
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Nav;
