import React, { useEffect, useState } from "react";
import footerlogo from "../assets/img/svg/footerLogo.svg";
import email from "../assets/img/webp/email.webp";
import location from "../assets/img/webp/location.webp";
import fb_logo from "../assets/img/svg/ibeify_fb_icon.svg";
import insta_logo from "../assets/img/svg/ibeify_insta_icon.svg";
import link_logo from "../assets/img/svg/ibeify_in_icon.svg";
import twitt_logo from "../assets/img/svg/ibeify_twitt_icon.svg";
import clouds1_img from "../assets/img/webp/clouds1_img.webp";
import cloud2_img from "../assets/img/webp/clouds2_img.webp";
import footer_overlay from "../assets/img/webp/footer_overlay.webp";

const Footer = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const today = new Date();
  const year = today.getFullYear();
  return (
    <>
      <section className="bg-[url('./assets/img/webp/footerbg.webp')] bg-cover relative z-20 pb-5 overflow-hidden">
        <img
          className=" absolute z-[-1] opacity-20 w-full h-full"
          src={footer_overlay}
          alt="footer_overlay"
        />
        <img
          className="absolute top-[15%] 2xs:max-w-[400px] md:max-w-[500px] xl:max-w-[600px] 2xxl:max-w-[700px] md:top-[1%]  z-[-10] cloud_1"
          src={clouds1_img}
          alt="clouds1_img"
        />
        <img
          className="absolute 2xs:max-w-[400px]  md:max-w-[600px] lg:max-w-[700px] 2xxl:max-w-[800px]  2xs:bottom-[0%] z-[-10] cloud_2"
          src={cloud2_img}
          alt="clouds2_img"
        />
        <div className="container px_75">
          <div className="grid lg:grid-cols-2 pt-[60px] lg:pt-[70px]">
            <div className="flex flex-col justify-center items-center text-center lg:justify-start lg:text-start lg:items-start">
              <a href="/">
                <img
                  className="w-[180px] sm:w-[200px] xl:w-[245px]"
                  src={footerlogo}
                  alt="footerlogo"
                />
              </a>
              <p className="ff_montserrat font-normal text-xs xl:text-sm lg:max-w-[380px] text-[#fff] opacity-70 mt-[17px]">
                Ibeify is a digital services company that provides scalable,
                resilient, and dynamic cloud solutions designed to power your
                continuous innovation.
              </p>
            </div>
            <div className=" flex  justify-around mt-8 lg:mt-3 lg:justify-end gap-[20px] md:gap-[44px]">
              <div className="flex-col flex items-center lg:items-start">
                <p className="ff_montserrat text-center lg:text-start font-semibold text-xs sm:text-base text-[#fff] opacity-90 mb-0">
                  Links
                </p>
                <a
                  href="#about-us"
                  className="ff_montserrat font-normal inline-block text-xs xl:text-sm text-[#fff] opacity-70 mt-3 xl:mt-5 hover:opacity-100 duration-300"
                >
                  About Us
                </a>
                <a
                  href="#service"
                  className="ff_montserrat hover:opacity-100 duration-300 font-normal inline-block text-xs xl:text-sm text-[#fff] opacity-70 mt-[6px]"
                >
                  Services
                </a>
                <a
                  href="#ourapproach"
                  className="ff_montserrat hover:opacity-100 duration-300 font-normal inline-block text-xs xl:text-sm text-[#fff] opacity-70 mt-[6px]"
                >
                  Our Approach
                </a>
              </div>
              <div>
                <p className="ff_montserrat text-center lg:text-start font-semibold text-xs sm:text-base text-[#fff] opacity-90">
                  Address
                </p>
                <div className="flex items-center gap-1 ">
                  <img src={location} alt="location" />
                  <a
                    target="_blank"
                    href="https://goo.gl/maps/4idycMqiwTD2xrMJ6"
                    className="ff_montserrat font-normal text-xs xl:text-sm text-[#fff] opacity-70 mt-3 xl:mt-5 hover:opacity-100 duration-300"
                  >
                    Houston, TX<br></br> 77008
                  </a>
                </div>
              </div>
              <div className="hidden sm:block">
                <p className="ff_montserrat  text-center lg:text-start font-semibold text-xs sm:text-base text-[#fff] opacity-90">
                  Contact Info
                </p>
                <div className="flex items-center gap-1 mt-3 xl:mt-5">
                  <img src={email} alt="email" />
                  <a
                    target="_blank"
                    href="mailto:info@ibeify.io"
                    className="ff_montserrat font-normal text-xs xl:text-sm text-[#fff] opacity-70 hover:opacity-100 duration-300"
                  >
                    info@ibeify.io
                  </a>
                </div>
              </div>
            </div>
            <div className=" text-center lg:text-start mt-8 sm:hidden block">
              <p className="ff_montserrat font-semibold text-xs sm:text-base text-[#fff] opacity-90">
                Contact Info
              </p>
              <div className="flex justify-center items-center gap-1 mt-4">
                <img src={email} alt="email" />
                <a
                  href="#"
                  className="ff_montserrat font-normal text-xs xl:text-sm text-[#fff] opacity-70 "
                >
                  info@ibeify.io
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr className=" border border-solid !text-[#606D7A] opacity-[0.3] mt-[60px] lg:mt-20" />
        <div className="container px_75 pt-4">
          <div className="flex-col-reverse  flex sm:flex-row justify-between items-center">
            <p className="ff_montserrat font-normal text-xs xl:text-sm text-[#9CA3AF] mt-3 sm:mt-0">
              © {year} ibeify. All rights reserved.
            </p>
            <div className="flex gap-3 mt-6 sm:mt-0">
              {/* <a
                href="https://www.facebook.com/"
                target="_blank"
                className="inline-block ease-in-out duration-300 hover:scale-[1.09]"
              >
                <img src={fb_logo} alt="fb_logo" />
              </a>
              <a
                href="https://www.instagram.com/"
                target="_blank"
                className="inline-block ease-in-out duration-300 hover:scale-[1.09]"
              >
                <img src={insta_logo} alt="insta_logo" />
              </a> */}
              <a
                href="https://www.linkedin.com/company/ibeify"
                target="_blank"
                className="inline-block ease-in-out duration-300 hover:scale-[1.09]"
              >
                <img src={link_logo} alt="link_logo" />
              </a>
              {/* <a
                href="https://www.twitter.com/"
                target="_blank"
                className="inline-block ease-in-out duration-300 hover:scale-[1.09]"
              >
                <img src={twitt_logo} alt="twitt_logo" />
              </a> */}
            </div>
          </div>
        </div>
        {/* back to top */}
        {showTopBtn && (
          <a
            href="#"
            className="fixed bottom-14  right-3 bg-lightgreen py-2 px-2 rounded-full"
            id="back-to-up"
            onClick={goToTop}
          >
            <span className="flex h-full justify-center items-center">
              <svg
                width="31"
                height="30"
                viewBox="0 0 31 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.1669 34.1675C15.6698 34.1675 16.1521 33.9677 16.5076 33.6121C16.8632 33.2566 17.0629 32.7743 17.0629 32.2715V10.3044L25.2006 18.4459C25.3768 18.6221 25.5861 18.762 25.8164 18.8574C26.0468 18.9528 26.2936 19.0019 26.5429 19.0019C26.7922 19.0019 27.0391 18.9528 27.2694 18.8574C27.4997 18.762 27.709 18.6221 27.8853 18.4459C28.0616 18.2696 28.2014 18.0603 28.2968 17.83C28.3922 17.5996 28.4413 17.3528 28.4413 17.1035C28.4413 16.8542 28.3922 16.6073 28.2968 16.377C28.2014 16.1467 28.0616 15.9374 27.8853 15.7611L16.5093 4.38514C16.3332 4.20858 16.124 4.06849 15.8936 3.97291C15.6633 3.87732 15.4163 3.82812 15.1669 3.82812C14.9176 3.82812 14.6706 3.87732 14.4403 3.97291C14.2099 4.06849 14.0007 4.20858 13.8246 4.38514L2.4486 15.7611C2.09259 16.1171 1.89258 16.6 1.89258 17.1035C1.89258 17.607 2.09259 18.0898 2.4486 18.4459C2.80462 18.8019 3.28749 19.0019 3.79097 19.0019C4.29445 19.0019 4.77732 18.8019 5.13334 18.4459L13.271 10.3044V32.2715C13.271 32.7743 13.4707 33.2566 13.8263 33.6121C14.1818 33.9677 14.6641 34.1675 15.1669 34.1675Z"
                  fill="#F5F5F5"
                />
              </svg>
            </span>
          </a>
        )}{" "}
      </section>
    </>
  );
};
export default Footer;
