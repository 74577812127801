import React from "react";
import hero_clouds1 from "../assets/img/webp/hero_clouds1.webp";
import HeroSpline from "./common/HeroSpline";

const Hero = () => {
  return (
    <>
      <div className="relative">
        <span className="dots1 top-[8%] xl:top-[0%] left-[2%] xl:-left-[2%] hero_dot absolute"></span>
        <span className="dots2 top-[5%] lg:top-[10%] xl:top-[-4%] left-[80%] sm:left-[50%] lg:left-[45%] absolute"></span>
        <span className="dots3 left-[8%] lg:left-[3%] bottom-[2%] xl:bottom-[-15%] absolute"></span>
        <img
          className="absolute top-0 lg:top-[0%] xl:top-[10%] hero_cloud1"
          src={hero_clouds1}
          alt="hero_clouds1"
        />
        <img
          className="absolute w-3/4 bottom-10 lg:bottom-[0%] xl:bottom-[-30%] hero_cloud2"
          src={hero_clouds1}
          alt="hero_clouds1"
        />
        <img
          className="absolute w-80 bottom-0 lg:bottom-[-60%] xl:bottom-[-40%] hero_cloud3"
          src={hero_clouds1}
          alt="hero_clouds1"
        />
        <div className="container relative px_75 lg:py-16 xl:py-0">
          <div className="flex items-center flex-col lg:flex-row pt-16 lg:pt-0">
            <div
              className="lg:w-[38%] relative z-[1]"
              data-aos="fade-right"
              data-aos-delay="2800"
            >
              <h1 className="ff_montserrat text-blue font-extrabold text-[28px] sm:text-[40px] md:text-[52px] xl:text-xl md:mb-3 leading-[60px] md:leading-[70px] xl:leading-[87.57px] lg:max-w-[550px] text-center lg:text-start">
                Welcome! To Ibeify
              </h1>
              <p className="ff_montserrat font-normal text-sm opacity-80 text-white mb-[35px] text-center lg:text-start">
                Ibeify is a digital services company that provides scalable,
                resilient, and dynamic cloud solutions designed to power your
                continuous innovation. We believe that technology is a powerful
                tool that can help unlock new possibilities and opportunities
                for your business, and we are here to support you every step of
                the way as you explore new horizons.
              </p>
              <div className="text-center lg:text-start">
                <a
                  href="IbeifyCapabilityStatement.pdf"
                  className="text-white text-sm font-semibold ff_montserrat py-[14px] px-6 border-white border-[1.5px] rounded-[100px]  hover:text-lightgreen hover:bg-white duration-300 inline-block"
                >
                  Capability Statement
                </a>
              </div>
            </div>
            <div
              className="hero_lottie "
              data-aos="fade-left"
              data-aos-delay="2900"
            >
              <div >
                <HeroSpline />
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default Hero;
